<script>
import appConfig from "@/app.config";
import PDFViewer from "../../../../layouts/pdf-viewer.vue";
import VueCountdown from "@chenfengyuan/vue-countdown";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

// tinymce editor
// import Editor from "@tinymce/tinymce-vue";

// ckeditor
import CKEditor from "@ckeditor/ckeditor5-vue";
import CustomEditor from "@/custom/ckeditor/build/ckeditor";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Pengerjaan Tes Tipe 8 Persiapan Interaksi",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PDFViewer,
    VueCountdown,
    // editor: Editor,

    ckeditor: CKEditor.component,
  },
  data() {
    return {
      id_jadwal: null,
      id_jadwal_detail: null,
      tipe: null,
      tipe_pengerjaan: null,

      url_backend_pdf_js: process.env.VUE_APP_BACKEND_URL + "/pdf.js/web/viewer.html?file=",

      is_petunjuk_soal_rich_text: false,
      content_petunjuk_soal_rich_text: "Silakan klik button petunjuk untuk memulai pengerjaan",

      editor: CustomEditor,
      editorData: "",

      name_soal: "",
      accordionPentunjuk: false,
      accordionSoal: false,

      file_embed: "",
      url_soal: "",
      url_petunjuk: "",
      jadwal_data: null,
      list_soal: [],
      list_petunjuk: [],

      isFullScreenJawaban: false,
      isFullScreenSoal: false,

      countDownSoal: 3,
      is_soal_enable: true,
      is_soal_active: null,
      last_update_soal: "-",

      waktu_mulai: 0,
      sisa_waktu: 3600000,

      txt_materi: "",
      txt_simulasi: "",
      jumlah_jawaban: "",
      zoomLevel: 1, // Default zoom level for image
    };
  },
  computed: {
    isImage() {
      return this.file_embed && (this.file_embed.endsWith('.jpg') || this.file_embed.endsWith('.jpeg') || this.file_embed.endsWith('.png') || this.file_embed.endsWith('.gif'));
    },
  },
  mounted() {
    let self = this;
    self.id_jadwal = self.$route.params.id_jadwal;
    self.id_jadwal_detail = self.$route.params.id_jadwal_detail;
    self.tipe = self.$route.params.tipe;
    self.tipe_pengerjaan = self.$route.params.tipe_pengerjaan;
    self.getJadwalData();
  },
  methods: {
    zoomIn() {
      this.zoomLevel += 0.1; // Increase zoom level
    },
    zoomOut() {
      if (this.zoomLevel > 0.1) {
        this.zoomLevel -= 0.1; // Decrease zoom level, preventing zooming out too much
      }
    },
    fullDateTimeFormat(datetime) {
      return moment(datetime).format("YYYY-MM-DD HH:mm");
    },
    getJadwalData() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Silakan klik button petunjuk untuk memulai pengerjaan",
        showConfirmButton: false,
      });

      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/jawaban-jadwal-data";
      var config = {
        method: "get",
        url: url,
        params: {
          id_jadwal_detail: self.id_jadwal_detail,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.jadwal_data = response_data.data;
            this.getInfoSimulasi();
            this.getDataDurasi();
            this.fetchPetunjuk();
            this.fetchSoal();
            Swal.close();
          } else {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.data.message,
            });
          }
        })
        .catch(() => {

        });
    },
    getInfoSimulasi() {
      let self = this;
      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "master/simulasi/" + self.jadwal_data.jadwal_detail.simulasi_id;
      var config = {
        method: "get",
        url: url,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data;
          self.txt_materi = response_data.data.level;
          self.txt_simulasi = response_data.data.nama_simulasi;
          self.jumlah_jawaban = response_data.data.simulasi_config.jumlah_jawaban;
        })
        .catch(() => {

        });
    },
    getDataDurasi() {
      let self = this;
      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/get-durasi-data";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal_batch: self.jadwal_data.jadwal_batch.id_jadwal_batch,
          id_jadwal_detail: self.jadwal_data.jadwal_detail.id,
          tipe: self.tipe,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.waktu_mulai = response_data.data;
            // var is_combo = self.jadwal_data.jadwal_detail.jadwal_new.j_is_combo;

            if (response_data.data == 0) {
              Swal.fire({
                icon: "warning",
                title: "Warning",
                text: "Waktu Pengerjaan Selesai, Menuju ke halaman detail jadwal.",
              });
              /*
              if (is_combo == "NO") {
                this.$router.push({
                  name: "kuesioner",
                  params: {
                    id_jadwal: self.id_jadwal,
                    id_simulasi:
                      self.jadwal_data.jadwal_batch.simulasi_jadwal_id,
                    pin: self.jadwal_data.jadwal_detail.pin,
                  },
                });
              } else {
                this.$router.push({
                  name: "kuesioner-combo",
                  params: {
                    id_jadwal: self.id_jadwal,
                    id_simulasi:
                      self.jadwal_data.jadwal_batch.simulasi_jadwal_id,
                    pin: self.jadwal_data.jadwal_detail.pin,
                  },
                });
              }
              */
              // this.$router.push({
              //   name: "kuesioner-combo",
              //   params: {
              //     id_jadwal: self.id_jadwal,
              //     id_simulasi: self.jadwal_data.jadwal_batch.simulasi_jadwal_id,
              //     pin: self.jadwal_data.jadwal_detail.pin,
              //   },
              // });
              this.$router.push({
                name: "detail-jadwal",
                params: {
                  id: this.id_jadwal,
                },
              });
            }
          } else {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.data.message,
            });
          }
        })
        .catch(() => {

        });
    },
    fetchPetunjuk() {
      let self = this;
      const apiUrl = process.env.VUE_APP_BACKEND_URL_VERSION + `petunjuk/${self.jadwal_data.jadwal_detail.id}`; // Replace with your API endpoint URL
      axios
        .get(apiUrl)
        .then((response) => {
          var responseData = response.data;
          self.list_petunjuk = responseData.data.data;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    fetchSoal() {
      let self = this;
      const apiUrl = process.env.VUE_APP_BACKEND_URL_VERSION + `soal/${self.jadwal_data.jadwal_detail.id}/${self.tipe}`; // Replace with your API endpoint URL
      axios
        .get(apiUrl)
        .then((response) => {
          var responseData = response.data;
          self.list_soal = responseData.data.data.list_soal;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    toggleAccordionPetunjuk(type) {
      let self = this;
      if (type == false) {
        self.accordionPentunjuk = true;
      } else {
        self.accordionPentunjuk = false;
      }
    },
    toggleAccordionSoal(type) {
      let self = this;
      if (type == false) {
        self.accordionSoal = true;
      } else {
        self.accordionSoal = false;
      }
    },
    petunjukTrigger(data) {
      var url;
      if (data.format_file == "Video" || data.format_file == "PPT" || data.format_file == "Excel") {
        this.is_petunjuk_soal_rich_text = false;
        this.content_petunjuk_soal_rich_text = "Silakan klik button petunjuk untuk memulai pengerjaan";
        url = data.link_yt;
      } else if (data.format_file == "PDF") {
        this.is_petunjuk_soal_rich_text = false;
        this.content_petunjuk_soal_rich_text = "Silakan klik button petunjuk untuk memulai pengerjaan";
        url = this.url_backend_pdf_js + process.env.VUE_APP_BACKEND_URL + data.file;
      } else if (data.format_file == "Rich Text") {
        this.is_petunjuk_soal_rich_text = true;
        this.content_petunjuk_soal_rich_text = data.rich_text;
        url = "";
      } else {
        this.is_petunjuk_soal_rich_text = false;
        this.content_petunjuk_soal_rich_text = "Silakan klik button petunjuk untuk memulai pengerjaan";
        url = process.env.VUE_APP_BACKEND_URL + data.file;
      }
      this.showFile(url, data, "Petunjuk");
      this.toggleTimerSoal();
    },
    toggleTimerSoal() {
      if (this.countDownSoal != 0) {
        this.timer = setInterval(() => {
          this.countDownSoal--;
          if (this.countDownSoal === 0) {
            clearInterval(this.timer);
            this.is_soal_enable = false;
            // countdown finished
          }
        }, 1000);
      }
    },
    async soalTrigger(data) {
      let self = this;
      self.name_soal = data.judul;
      self.is_soal_active = data;
      if (self.jumlah_jawaban == "1 Soal 1 Jawaban") {
        await self.getDataJawabanTerakhir();
        await self.postDataSoalTrigger();
      } else {
        await self.postDataSoalTrigger();
      }
      var url = "";
      if (data.tipe == "Video" || data.tipe == "PPT" || data.tipe == "Excel") {
        self.is_petunjuk_soal_rich_text = false;
        self.content_petunjuk_soal_rich_text = "Silakan klik button petunjuk untuk memulai pengerjaan";
        url = data.link_yt;
      } else if (data.tipe == "PDF") {
        self.is_petunjuk_soal_rich_text = false;
        self.content_petunjuk_soal_rich_text = "Silakan klik button petunjuk untuk memulai pengerjaan";
        url = self.url_backend_pdf_js + process.env.VUE_APP_BACKEND_URL + data.file;
      } else if (data.tipe == "Rich Text") {
        self.is_petunjuk_soal_rich_text = true;
        self.content_petunjuk_soal_rich_text = data.rich_text;
        url = "";
      } else {
        self.is_petunjuk_soal_rich_text = false;
        self.content_petunjuk_soal_rich_text = "Silakan klik button petunjuk untuk memulai pengerjaan";
        url = process.env.VUE_APP_BACKEND_URL + data.file;
      }
      self.showFile(url, data, "Materi");
    },
    // showFile(file_petunjuk) {
    //   this.file_embed = file_petunjuk;
    // },
    async showFile(file_petunjuk, file_data, tipe) {
      let self = this;
      if (file_data.tipe == "PDF" || file_data.format_file == "PDF") {
        var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/generate-watermark-pdf";
        var config = {
          method: "post",
          url: url,
          data: {
            url_file: file_data.file,
            tipe: tipe,
            watermark: localStorage.session_username
          },
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.access_token,
            access: "all-role",
            role_id: localStorage.session_role_active_id,
          },
        };
        await axios(config)
          .then((response) => {
            if (response?.data?.data?.file_url) {
              this.file_embed = self.url_backend_pdf_js + process.env.VUE_APP_BACKEND_URL + response.data.data.file_url;
            }
          })
          .catch(() => {

          });
      } else {
        this.file_embed = file_petunjuk;
      }

    },
    handleCountdownProgress(data) {
      let self = this;
      self.sisa_waktu = data.totalMinutes;
      var count_second = data.seconds;
      if (count_second % 30 == 0) {
        // interval 30 seconds.
        self.postAutoHistory();
      }

      if (count_second == 0) {
        this.checkDurasi();
      }


    },
    postAutoHistory() {
      let self = this;
      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/store-auto-history-data";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal: self.id_jadwal,
          id_jadwal_detail: self.id_jadwal_detail,
          tipe: self.tipe,
          tipe_pengerjaan: self.tipe_pengerjaan,
          sisa_waktu: self.sisa_waktu,
          nip: self.jadwal_data.jadwal_detail.nip_asesi,
          pin: self.jadwal_data.jadwal_detail.pin,
          jawaban: self.editorData,
          soal_data: JSON.stringify(self.is_soal_active),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config)
        .then(() => {
        })
        .catch(() => {

        });
    },
    async postDataSoalTrigger() {
      let self = this;
      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/store-soal-trigger";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal: self.id_jadwal,
          id_jadwal_detail: self.id_jadwal_detail,
          tipe: self.tipe,
          tipe_pengerjaan: self.tipe_pengerjaan,
          sisa_waktu: self.sisa_waktu,
          nip: self.jadwal_data.jadwal_detail.nip_asesi,
          pin: self.jadwal_data.jadwal_detail.pin,
          jawaban: self.editorData,
          soal_data: JSON.stringify(self.is_soal_active),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      await axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            self.editorData = response_data.data.jawaban_history;
            self.last_update_soal = response_data.data.last_update;

          }
        })
        .catch(() => {

        });
    },
    postAnswer() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/store";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal: self.id_jadwal,
          id_jadwal_detail: self.id_jadwal_detail,
          tipe: self.tipe,
          tipe_pengerjaan: self.tipe_pengerjaan,
          sisa_waktu: self.sisa_waktu,
          nip: self.jadwal_data.jadwal_detail.nip_asesi,
          pin: self.jadwal_data.jadwal_detail.pin,
          jawaban: self.editorData,
          soal_data: JSON.stringify(self.is_soal_active),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Berhasil simpan Jawaban.",
            });
          } else {

            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.data.message,
            });
          }
        })
        .catch((errors) => {

          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errors,
          });
        });
    },
    checkDurasi() {
      let self = this;
      if (self.sisa_waktu == 0) {
        self.submitTestDone();
      }
    },
    submitTestDone() {
      let self = this;
      self.postAnswer();
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/submit-test-done";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal_detail: self.id_jadwal_detail,
          sisa_waktu: self.sisa_waktu,
          tipe: self.tipe,
          tipe_pengerjaan: self.tipe_pengerjaan,
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };
      // var is_combo = self.jadwal_data.jadwal_detail.jadwal_new.j_is_combo;
      axios(config)
        .then((response) => {
          let response_data = response.data;
          if (response_data.meta.code == 200) {
            Swal.close();
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Pengerjaan Selesai, Menuju ke halaman Kuesioner.",
            });
            // if (is_combo == "NO") {
            //   this.$router.push({
            //     name: "kuesioner",
            //     params: {
            //       id_jadwal: self.id_jadwal,
            //       id_simulasi: self.jadwal_data.jadwal_batch.simulasi_jadwal_id,
            //       pin: self.jadwal_data.jadwal_detail.pin,
            //     },
            //   });
            // } else {
            // this.$router.push({
            //   name: "kuesioner-combo",
            //   params: {
            //     id_jadwal: self.id_jadwal,
            //     id_simulasi: self.jadwal_data.jadwal_batch.simulasi_jadwal_id,
            //     pin: self.jadwal_data.jadwal_detail.pin,
            //   },
            // });
            // }
            this.$router.push({
              name: "detail-jadwal",
              params: {
                id: this.id_jadwal,
              },
            });
          } else {

            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.data.message,
            });
          }
        })
        .catch((errors) => {

          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: errors,
          });
        });
    },
    halamanDetailJadwal() {
      this.$router.push({
        name: "detail-jadwal",
        params: {
          id: this.$route.params.id_jadwal,
        },
      });
    },
    toggleFullScreenJawaban() {
      const card = this.$el.querySelector(".card-jawaban");
      card.classList.toggle("full-screen-card-jawaban");
      this.isFullScreenJawaban = !this.isFullScreenJawaban;
    },
    toggleFullScreenSoal() {
      const card = this.$el.querySelector(".card-petunjuk");
      card.classList.toggle("full-screen-card-soal");
      this.isFullScreenSoal = !this.isFullScreenSoal;
    },
    toggleFullScreen(section) {
      if (section === "soal") {
        this.isFullScreenSoal = !this.isFullScreenSoal;
      } else if (section === "jawaban") {
        this.isFullScreenJawaban = !this.isFullScreenJawaban;
      }
    },
    async getDataJawabanTerakhir() {
      let self = this;
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var url = process.env.VUE_APP_BACKEND_URL_VERSION + "jawaban/jawaban-terakhir";
      var config = {
        method: "post",
        url: url,
        data: {
          id_jadwal: self.id_jadwal,
          id_jadwal_detail: self.id_jadwal_detail,
          tipe: self.tipe,
          tipe_pengerjaan: self.tipe_pengerjaan,
          sisa_waktu: self.sisa_waktu,
          nip: self.jadwal_data.jadwal_detail.nip_asesi,
          pin: self.jadwal_data.jadwal_detail.pin,
          soal_data: JSON.stringify(self.is_soal_active),
        },
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
          access: "all-role",
          role_id: localStorage.session_role_active_id,
        },
      };

      await axios(config)
        .then((response) => {
          let response_data = response.data.data;
          if (response_data.catatan_lgd == "" || response_data.catatan_lgd == null) {
            self.editorData = "<h3>Masukkan Jawaban Anda Disini.</h3>";
          } else {
            self.editorData = response_data.catatan_lgd;
          }
          Swal.close();
        })
        .catch(() => {

          Swal.close();
        });
    },
  },
};
</script>
<template>
  <div class="wrapper-jawaban">
    <div class="row">
      <div class="col-6">
        <button
          class="btn btn-warning mt-2 btn-sm"
          @click="halamanDetailJadwal()"
        ><i class="fa fa-arrow-left"></i> Kembali</button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-6">
            <div
              class="judul"
              v-if="txt_materi && txt_simulasi"
            >
              <h4>{{ txt_materi }} - Materi {{ txt_simulasi }}</h4>
            </div>
          </div>
          <div class="col-6">
            <div class="text-end">
              <span class="h4">Sisa Waktu
                <span id="timer">
                  <vue-countdown
                    :time="waktu_mulai"
                    v-slot="{ hours, minutes, seconds }"
                    @progress="handleCountdownProgress"
                  > {{ hours }} : {{ minutes }} : {{ seconds }} </vue-countdown>
                </span>
              </span>
              <button
                class="btn btn-success ms-4 btn-md"
                v-on:click="submitTestDone"
              ><i class="fa fa-check"></i> Selesai Mengerjakan</button>
            </div>
          </div>
        </div>
        <div class="row mt-4">

          <div class="col-1">
            <!-- Komponen Petunjuk -->
            <b-card no-body>
              <b-card-header
                header-tag="header"
                class="p-3"
                role="tab"
              >
                <div class="d-grid gap-2">
                  <b-button
                    block
                    v-b-toggle.collapse1
                    variant="info"
                    style="font-size: 10px;"
                    v-on:click="toggleAccordionPetunjuk(accordionPentunjuk)"
                  >
                    Petunjuk&nbsp;
                    <i
                      class="float-right"
                      :class="{
                        'fa fa-chevron-up': accordionPentunjuk,
                        'fa fa-chevron-down': !accordionPentunjuk,
                      }"
                    ></i>
                  </b-button>
                </div>
              </b-card-header>
              <b-collapse
                id="collapse1"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <ul
                    class="submenu"
                    v-for="(petunjuk, key) in list_petunjuk"
                    :key="key"
                  >
                    <li>
                      <a
                        href="javascript:void(0)"
                        @click="petunjukTrigger(petunjuk)"
                        style="font-size: 10px;"
                      >
                        {{ petunjuk.judul_file }}
                      </a>
                    </li>
                  </ul>
                </b-card-body>
              </b-collapse>
            </b-card>

            <!-- Komponen Soal -->
            <b-card
              no-body
              class="mt-1"
            >
              <b-card-header
                header-tag="header"
                class="p-3"
                role="tab"
              >
                <div class="d-grid gap-2">
                  <b-button
                    block
                    v-b-toggle.collapse2
                    variant="info"
                    style="font-size: 10px;"
                    :disabled="is_soal_enable"
                    v-on:click="toggleAccordionSoal(accordionSoal)"
                  >
                    Materi&nbsp;
                    <i
                      class="float-right"
                      :class="{
                        'fa fa-chevron-up': accordionSoal,
                        'fa fa-chevron-down': !accordionSoal,
                      }"
                    ></i>
                  </b-button>
                </div>
              </b-card-header>
              <b-collapse
                id="collapse2"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <ul
                    class="submenu"
                    :style="{ maxHeight: '200px', overflowY: 'auto', paddingRight: '10px' }"
                  >
                    <li
                      v-for="(soal, key) in list_soal"
                      :key="key"
                    >
                      <a
                        href="javascript:void(0)"
                        @click="soalTrigger(soal)"
                        style="font-size: 10px; display: block; border-bottom: 1px solid #ccc; padding-bottom: 5px; margin-bottom: 5px;"
                      >
                        {{ soal.judul }}
                      </a>
                    </li>
                  </ul>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
          <div :class="{
            'col-6 order-1': !isFullScreenSoal && !isFullScreenJawaban,
            'col-11 order-1': isFullScreenSoal && !isFullScreenJawaban,
            'col-12 order-2': !isFullScreenSoal && isFullScreenJawaban,
          }">
            <div class="card">
              <div class="card-header d-flex justify-content-between align-items-center no-margin">
                <div>
                  <!-- Your existing buttons -->
                </div>
                <div>
                  <!-- New button for full-screen -->
                  <button
                    :class="{
                      'btn btn-primary': !isFullScreenSoal,
                      'btn btn-danger': isFullScreenSoal,
                    }"
                    @click="toggleFullScreen('soal')"
                  >
                    <i
                      v-if="!isFullScreenSoal"
                      class="fas fa-expand-alt"
                    ></i>
                    <i
                      v-else
                      class="fas fa-times"
                    ></i>
                  </button>
                </div>
              </div>
              <div class="card-body mt-2">
                <div v-if="is_petunjuk_soal_rich_text == true">
                  <!-- <editor
                    api-key="m1l04baz8dzyto2x8wz86rypvdo0ev13809mfj6r4a2pvcdi"
                    :init="{
                      height: 300,
                      menubar: false,
                      plugins: [],
                      toolbar: '',
                      contextmenu_never_use_native: true,
                      paste_preprocess: function (plugin, args) {
                        args.content = '';
                      },
                    }"
                    v-model="content_petunjuk_soal_rich_text"
                  /> -->
                  <ckeditor
                    v-model="content_petunjuk_soal_rich_text"
                    :editor="editor"
                  ></ckeditor>
                </div>
                <div v-else>
                  <div v-if="file_embed">
                    <div v-if="isImage">
                      <div class="image-wrapper">
                        <img
                          :src="file_embed"
                          :style="{ transform: 'scale(' + zoomLevel + ')', transition: 'transform 0.25s ease' }"
                        />
                      </div>
                      <div class="zoom-controls">
                        <button
                          @click="zoomIn"
                          class="btn btn-primary"
                        >Zoom In</button>
                        <button
                          @click="zoomOut"
                          class="btn btn-primary"
                        >Zoom Out</button>
                      </div>
                    </div>

                    <!-- If file_embed is a PDF, use the PDF viewer -->
                    <div v-else>
                      <PDFViewer :pdfUrl="file_embed" />
                    </div>
                  </div>
                  <div v-else>Silakan klik button petunjuk untuk memulai pengerjaan</div>
                </div>
              </div>
            </div>
          </div>
          <div :class="{
            'col-5 order-1': !isFullScreenJawaban && !isFullScreenSoal,
            'col-11 order-1': isFullScreenJawaban && !isFullScreenSoal,
            'col-12 order-2': !isFullScreenJawaban && isFullScreenSoal,
          }">
            <div class="card">
              <div class="card-header d-flex justify-content-between align-items-center no-margin">
                <div class="d-flex align-items-center">
                  <span class="font-weight-semibold">
                    Lihat Panduan Lembar Jawaban dengan
                    <a
                      href="https://docs.google.com/document/d/e/2PACX-1vRidDF3m6B666ErPT7RvAFjjZijBEzDlEIcbWHtz7-BI-IEYheEMPS2_FVrKC_I2_-KAAz1tuyGUm_T/pub"
                      target="_blank"
                    > Klik Disini </a>
                  </span>
                </div>
                <div>
                  <!-- New button for full-screen -->
                  <button
                    :class="{
                      'btn btn-primary': !isFullScreenJawaban,
                      'btn btn-danger': isFullScreenJawaban,
                    }"
                    @click="toggleFullScreen('jawaban')"
                  >
                    <i
                      v-if="!isFullScreenJawaban"
                      class="fas fa-expand-alt"
                    ></i>
                    <i
                      v-else
                      class="fas fa-times"
                    ></i>
                  </button>
                </div>
              </div>

              <div class="card-body">
                <h4>Catatan Persiapan</h4>
                <!-- <editor
                  api-key="m1l04baz8dzyto2x8wz86rypvdo0ev13809mfj6r4a2pvcdi"
                  :init="{
                    height: 500,
                    menubar: true,
                    plugins: ['table'],
                    paste_preprocess: function (plugin, args) {
                      args.content = '';
                    },
                    toolbar:
                      'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                    contextmenu_never_use_native: true,
                  }"
                  v-model="editorData"
                /> -->
                <ckeditor
                  v-model="editorData"
                  :editor="editor"
                ></ckeditor>
                <div v-show="is_soal_active != null">
                  <div class="row mt-1 mb-1">
                    <div class="col-6">Last Update :
                      {{ last_update_soal == "-" || last_update_soal == null ? "-" :
                        fullDateTimeFormat(last_update_soal)
                      }}</div>
                    <!-- <div class="col-6 text-end">
                      <button
                        class="btn btn-sm btn-success"
                        v-on:click="postDataSoalTrigger()"
                      ><i class="mdi mdi-refresh"></i></button>
                    </div> -->
                  </div>
                  <div class="row">
                    <button
                      class="btn btn-md btn-primary"
                      v-on:click="postAnswer()"
                    ><i class="fa fa-save"></i> Simpan</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.no-margin {
  padding: 5 !important;
  background-color: rgb(243, 243, 243);
  border-color: aqua;
  border: 1px;
}

.card-header {
  border: 2px solid rgb(255, 255, 255);
  /* Customize the border color and other border properties */
}

.submenu {
  list-style-type: none;
  padding: 0;
}

.submenu li {
  margin-bottom: 5px;
}

.submenu a {
  text-decoration: none;
  color: #333;
  /* Change the link color */
}

.submenu a:hover {
  color: #007bff;
  /* Change the link color on hover */
}

/* Divider style */
.submenu .divider {
  border-top: 1px solid #ccc;
  margin: 5px 0;
  padding: 0;
}

.wrapper-jawaban {
  padding: 30px;
  /* Customize the border color and other border properties */
}

.full-screen-card-soal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  z-index: 1000;
  background-color: white;
  overflow: auto;
  /*
  /* Additional styles for full screen */
}

.full-screen-card-jawaban {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  z-index: 1000;
  background-color: white;
  overflow: auto;
  /*
  /* Additional styles for full screen */
}

.image-wrapper {
  max-height: 500px;
  /* Set the max height */
  overflow: auto;
  /* Enable scrolling if the content overflows */
  position: relative;
  border: 1px solid #ccc;
  /* Optional, to give the wrapper some visual border */
}

img {
  display: block;
  /* Remove the inline display property for better control */
  width: auto;
  /* Keep width auto to maintain aspect ratio */
  height: auto;
  /* Keep height auto to maintain aspect ratio */
}

.zoom-controls {
  margin-top: 10px;
}

button {
  margin: 5px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}
</style>
